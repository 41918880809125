<template>
  <div class="actions">
    <router-link
      :to="{name: 'chirurgie_detail', params: {uid: object.uid}}"
      href="javascript:void(0)"
      class="btn btn-sm  btn-secondary"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a 
      class="btn btn-primary btn-sm"
      data-toggle="modal"
      data-target="#update-chirurgie"
      @click.prevent="setSelected"
      v-if="canUpdate"
    >
      <i class="icofont icofont-pencil" />
    </a>
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-third"
      @click.prevent="deleting"
      v-if="canDelete"
    >
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>
  
  <script>
  import { DELETE_CHIRURGIE } from '../../../graphql/sanitaire'; 
  import { mapMutations, mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
        ...mapMutations({
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        setSelected(){
            this.setSelectedObject(this.object)
        },
          deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_CHIRURGIE,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`chirurgie ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting chirurigie ${this.object.uid}`)
                  }
                })
          }
      },
      computed:{
        ...mapGetters({
            is_super_admin: 'auth/is_super_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        }),
        canUpdate(){
          return this.is_super_admin ||
            this.is_veterinaire ||
            this.is_assistant_veto_major
        },
        canDelete(){
          return this.is_super_admin ||
            this.is_veterinaire 
        }
      }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .actions{
      a{
          margin-left: 5px;
      }
  }
  </style>